define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-selected-filters", ["exports", "@glimmer/component", "@ember/helper", "discourse/components/d-button", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _dButton, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MvpSelectedFilters extends _component.default {
    labelFor(selection) {
      const [name, value] = selection.split("|");
      let label;
      switch (name) {
        case "countries":
          label = _I18n.default.t(`discourse_uipath_mvp.countries.${value}`);
          break;
        case "languages":
          label = _I18n.default.t(`discourse_uipath_mvp.languages.${value}`);
          break;
        case "regions":
          label = _I18n.default.t(`discourse_uipath_mvp.regions.${value}`);
          break;
        default:
          label = value;
      }
      return label;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#each @selections as |selection|}}
          <DButton
            class="btn-small btn-icon-text mvp-directory-profiles-selected-filters__item"
            @action={{fn @onRemove selection}}
            @icon="xmark"
            @translatedLabel={{this.labelFor selection}}
          />
        {{/each}}
      
    */
    {
      "id": "/cQ+3jWz",
      "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[32,0],[[24,0,\"btn-small btn-icon-text mvp-directory-profiles-selected-filters__item\"]],[[\"@action\",\"@icon\",\"@translatedLabel\"],[[28,[32,1],[[30,3],[30,2]],null],\"xmark\",[28,[30,0,[\"labelFor\"]],[[30,2]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[\"@selections\",\"selection\",\"@onRemove\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-selected-filters.js",
      "scope": () => [_dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpSelectedFilters;
});