define("discourse/plugins/discourse-uipath-mvp/discourse/connectors/user-profile-controls/high-impact-activity-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/models/category", "discourse/plugins/discourse-uipath-mvp/discourse/components/create-high-impact-activity-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _service, _category3, _createHighImpactActivityButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HighImpactActivityButton extends _component.default {
    static shouldRender(outletArgs, _ref) {
      let {
        currentUser,
        siteSettings
      } = _ref;
      return currentUser === outletArgs.model && currentUser.can_see_mvp_features && siteSettings.mvp_high_impact_activity_category !== "";
    }
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "category", [_tracking.tracked]))();
    #category = (() => (dt7948.i(this, "category"), void 0))();
    async loadCategory() {
      const categoryId = parseInt(this.siteSettings.mvp_high_impact_activity_category, 10);
      this.category = await _category3.default.findById(categoryId);
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadCategory", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li {{didInsert this.loadCategory}}>
          {{#if this.category}}
            <CreateHighImpactActivityButton
              @category={{this.category}}
              @icon="pencil"
            />
          {{/if}}
        </li>
      
    */
    {
      "id": "haU1fCRY",
      "block": "[[[1,\"\\n    \"],[11,\"li\"],[4,[32,0],[[30,0,[\"loadCategory\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"category\"]],[[[1,\"        \"],[8,[32,1],null,[[\"@category\",\"@icon\"],[[30,0,[\"category\"]],\"pencil\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/connectors/user-profile-controls/high-impact-activity-button.js",
      "scope": () => [_didInsert.default, _createHighImpactActivityButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HighImpactActivityButton;
});